/* ==================
	4. Heading
=================== */
section{
    position: relative;
	z-index:1;
}
.heading-bx{
	margin-bottom:45px;
	
	.title-ext{
		font-weight: 700;
		display: inline-block;
		padding: 3px 15px;
		background-position: center;
		background-repeat: repeat;
		margin-bottom: 15px;
		&.text-primary{
			background-image: url(../../../images/shap/ext-blue.png);
		}
		&.text-secondary{
			background-image: url(../../../images/shap/ext-orange.png);
		}
	}
	h2.title{
		font-size:45px;
		@include respond('laptop-small'){
			font-size: 36px;		
		}
		@include respond('phone-land'){
			font-size: 32px;
		}
		@include respond('phone'){
			font-size: 28px;
		}
	}
	@include respond('tab-port'){
		margin-bottom:30px !important;
	}
	@include respond('tab-land'){
		margin-bottom:40px;
	}
	&.text-white{
		h1, h2, h3, h4, h5, h6{
			color:$white;
		}
	}
	p{
		padding-top:5px;
		font-weight: 500;
		@include respond('laptop'){
			font-size:15px;
		}
		@include respond('tab-land'){
			font-size:14px;	
			line-height: 24px;			
		}
	}
	&.text-center p{
		max-width:800px;
		margin-left:auto;
		margin-right:auto;
	}
	&.head-col{
		display:flex;
		align-items: center;	
		@include respond('phone-land'){
			display:block;
		}
		.title{
			margin-bottom:0;
			@include respond('phone-land'){
				margin-bottom:10px;
			}
		}
		p{
			padding-left: 15px;
			border-left: 4px solid $primary;
			margin-left: 15px;
			margin-bottom: 0;
			@include respond('phone-land'){
				padding-left: 0;
				border-left:0;
				margin-left: 0;
			}
		}
	}
}
.text-white{
	h1, h2, h3, h4, h5, h6{
		color:$white;
	}
}